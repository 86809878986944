.reviews {
  padding: 100px 0 120px;
  background-image: url(../images-ss/reviews-bcg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  @include media("<=1080px") {
    padding: 80px 0 100px;
  }
}

.reviews__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 72px;
  row-gap: 20px;
  column-gap: 20px;
  @include media("<=810px") {
    column-gap: 10px;
    flex-direction: column;
    padding-top: 80px;
  }
  @include media("<=430px") {
    
  }
}

.reviews__item {
  max-width: 590px;
  flex: 0 1 48%;
  aspect-ratio: 6 / 3.5;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 32px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 40px rgba(186, 186, 201, 0.2);
  @include media("<=1080px") {
    padding: 24px;
  }
  @include media("<=810px") {
    flex: 0 1 100%;
    max-width: 100%;
    aspect-ratio: auto;
  }
@include media("<=430px") {
 

}
&:last-of-type {
  max-width: 100%;
  flex: 0 1 100%;
  aspect-ratio: 4 / 1.1;
  @include media("<=810px") {
    aspect-ratio: auto;
  }
}
}

.reviews__title {
  color: #000000;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  column-gap: 23px;
  margin-bottom: 20px;
  @include media("<=430px") {
    
    padding-top: 10px;
  }
}

.reviews__text {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
  @include media("<=1080px") {
    font-size: 18px;
  }
  @include media("<=430px") {
    font-size: 14px;
  }
}