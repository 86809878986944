.features {
   padding: 64px 0 67px;
  @include media("<=810px") {
 
  }
    @include media("<=430px") {
      padding-bottom: 20px;
    }
}

.features__inner {
  padding-top: 67px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 40px;
  row-gap: 20px;
  @include media("<=1080px") {
    column-gap: 10px;
  }
    @include media("<=630px") {
      flex-direction: column;
      align-items: center;
      padding-top: 40px;
    }
}

.features__video {
  max-width: 462px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  
}

.tiktok-embed {

}

.features__content {
  max-width: 458px;
  width: 100%;
}

.features__title {
  color: #F09248;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.features__list {
  display: flex;
  flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 18px;
    width: 100%;
}

.list__item {
  display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #828282;
    font-size: 18px;
    column-gap: 16px;
    width: 100%;
    &::before {
      content: "";
       max-width: 24px;
       width: 100%;
       height: 24px;
       background-image: url(../images-ss/check.svg);
       background-position: center;
    }
}