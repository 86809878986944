.hero {
  background-image: url(../images-ss/hero-bcg.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow-x: clip;
  width: 100%;
  aspect-ratio: 120 / 67;
    @include media("<=1280px") {
      
    }
    @include media("<=1080px") {
    
    }
  @include media("<=810px") {
     padding: 140px 0 120px;
     aspect-ratio: auto;
  }
  @include media("<=430px") {
   
  }
  @include media("<=375px") {
  }
  @include media("<=320px") {
  }
}

.hero__inner {
 
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  @include media("<=1280px") {
    
  }
  @include media("<=1080px") {
 
  }
  @include media("<=810px") {
    position: relative;
    flex-direction: column;
    
  }
  @include media("<=420px") {
   
  }
}

.hero__content {
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include media("<=810px") {
    align-items: center;
    max-width: 600px;
  }
}

.hero__title {
  color: #3D75FE;
    text-shadow: 0px 4px 0px #000;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #1F1A1A;
    font-family: Jost;
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 187px;
  @include media("<=1440px") {
    
  }
  @include media("<=1280px") {
    margin-bottom: 120px;
  }
  @include media("<=1080px") {
    margin-bottom: 80px;
  }
  @include media("<=810px") {
   text-align: center;
  }
  @include media("<=430px") {
    font-size: 54px;
  }

  @include media("<=350px") {
   font-size: 40px;
  }
  img {
    width: 100%;
    display: block;
  }
}

.hero__subtitle {
  color: #514F4F;
  font-size: 18px;
  margin-bottom: 54px;
  @include media("<=810px") {
    text-align: center;
    margin-bottom: 24px;
  }
}

.link {
  max-width: 196px;
  img {
    width: 100%;
    display: block;
  }
}


.button {
  border-radius: 40px;
    background: #6592E6;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  color: #F6F6F6;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding: 14px;
  max-width: 145px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  z-index: 2;
  border-radius: 40px;
  transition: 0.4s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
  @include media("<=1280px") {
    
  }
  @include media("<=1080px") {
 
    
  }
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
    
   
    
  }
  &:hover {
    background: #1961E7;
  }
  span {
    font-weight: 700;
  }
}






