iframe {
  width: 100%;
  height: 100vh;
  @include media("<=tablet") {
    height: 83vh;
  }
  @include media("<=736px") {
    height: 100vh;
  }
  @include media("<=420px") {
    height: 77vh;
  }
}

.page-content {
  padding: 104px 0 64px;

  @include media("<=810px") {
    padding: 40px 0;
  }
  
}

.page-inner {
  padding-top: 40px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  @include media("<=420px") {
    padding-top: 30px;
    font-size: 16px;
  }

  p {
    
    line-height: 1.2;
    @include media("<=420px") {
     
    }
  }
  h3 {
    line-height: 1.1;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 20px;

    @include media("<=420px") {
      margin-bottom: 18px;
      font-size: 18px;
    }
  }
}


.page__title {
  width: 100%;
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  @include media("<=810px") {
    font-size: 42px;
  }
    @include media("<=430px") {
      font-size: 28px;
    }
}

.page-text {
  font-weight: 600;
  line-height: 1.2;
}

.page__row {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  @include media("<=810px") {
    row-gap: 30px;
    flex-wrap: wrap;
  }
}

.page__item {
  flex: 0 1 24%;
  @include media("<=810px") {
    flex: 0 1 47%;
  }
  img {
    width: 100%;
    display: block;
  }
}