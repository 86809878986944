.footer {
  background: #226093;
  padding: 62px 0;
  position: relative;
  @include media("<=810px") {}

  @include media("<=430px") {
    padding: 30px 0;
  }

}

.footer__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
 
margin-bottom: 16px;
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
  }
}

.footer__nav {
  position: relative;
 
  display: flex;
  flex-direction: column;

  justify-content: center;
  row-gap: 20px;
  @include media("<=430px") {
   flex-direction: column;
   padding-bottom: 20px;
  }
 
}

.footer__links {
  
 
  
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: space-between;
  @include media("<=810px") {
    max-width: 500px;
    margin-bottom: 20px;
  }
@include media("<=430px") {
  flex-direction: column;
}
}

.footer__link {
  color: #FFFFFF;
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    @include media("<=810px") {
      text-align: center;
      margin-bottom: 20px;
    }
  
    @include media("<=430px") {
      font-size: 18px;
      
    }
  
    &:hover {
      text-decoration: underline;
    }
  @include media("<=810px") {
    align-items: center;
  }
}

.copyright {
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
  @include media("<=810px") {
    order: 3;
  }
}


