.info {   
    padding-bottom: 50px;
    position: relative;
    overflow: hidden;
    @include media("<=430px") {
        padding: 80px 0;
    }
    
}

.info__inner {
    border-radius: 8px;
        background: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    padding: 24px;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    height: 400px;
    row-gap: 30px;
    position: relative;
    z-index: 3;
    @include media("<=1080px") {
        
    }
    @include media("<=430px") {
        height: auto;
        padding: 30px 20px;
    }
}

.info__content {
    padding-left: 24px;
    @include media("<=430px") {
       padding-left: 0;
    }
}

.info__title {
    font-size: 48px;
    font-weight: 600;
    @include media("<=610px") {
font-size: 24px;
    }
}

.info__text {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    padding: 18px 0 24px;
    @include media("<=810px") {
        
    }
  @include media("<=430px") {
    font-size: 16px;
  }
  
}

.icon {
    max-width: 24px;
    width: 100%;
    height: 24px;
    background-image: url(../images-ss/android.png);
}

.info__logo {
    max-width: 211px;
    width: 100%;
    @include media("<=610px") {
        max-width: 110px;
    }
    img {
        width: 100%;
        display: block;
    }
}