.contacts {
  padding: 64px 0;
}

.contacts__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    padding-top: 32px;
    @include media("<=810px") {
      flex-direction: column;
      row-gap: 30px;
    }
}

.contacts__content {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
        align-items: center;
        justify-content: space-between;
        max-width: 508px;
        width: 100%;
}

.contacts__item {
    height: 142px;
    border-radius: 8px;
        background: #FAFAFA;
        width: 100%;
        column-gap: 12px;
        display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 9px 32px;
        @include media("<=600px") {
          padding: 9px;
          min-height: 140px;
          height: auto;
        }
}

.contacts__text {
    font-size: 19px;
    line-height: 1.2;
    span {
        display: block;
        font-size: 32px;
        font-weight: 700;
    }
    a {
        color: #6592E6;
        text-decoration: none;
        @include media("<=430px") {
            display: block;
            line-height: 1.3;
        }
    }
}

.contacts__image {
    max-width: 467px;
    width: 100%;
    img {
        width: 100%;
        display: block;
    }
}