.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 14px 0;
  background: #226093;
  @include media("<=810px") {
    position: relative;
    padding: 20px 0;
  }
  @include media("<=430px") {
   
  }
  
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__nav {
  column-gap: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include media("<=1080px") {
    max-width: 460px;
  }
  @include media("<=810px") {
    display: none;
    max-width: 100%;
    &.active {
      display: flex;
      row-gap: 15px;
      position: fixed;
      height: 75%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      padding-top: 20px;
      z-index: 20;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: space-between;
      align-items: center;
      background: #226093;
      opacity: 0.97;

      .header__link {
        padding: 20px;
        margin-right: 0;
        @include media("<=430px") {
          
        }
        @include media("<=330px") {
         
        }
      }
    }
  }
  
}

.header__link {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #F6F6F6;
  transition: all 0.3s linear;
  position: relative;
  @include media("<=810px") {
    font-size: 20px;
    border-bottom: 1px solid #9CB7CE;
    width: 90%;
    text-align: center;
  }
  @include media("<=430px") {
    
  }
  &:hover {
    color: #C5C5C5;
  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.12em;
  background: #ffffff;
  bottom: -7px;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.logo {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #F6F6F6;
  font-size: 19px;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  @include media("<=1440px") {
  
  }
  @include media("<=810px") {

  }
  
  img {
    width: 100%;
    max-width: 43px;
  }
}
