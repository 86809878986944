body {
  margin: 0;
  font-family: "Jost", sans-serif;
  background: #f6f6f6;
  color: #1F1A1A;
  position: relative;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
  }
  
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1030px;
  padding: 0 15px;
  margin: 0 auto;
}

.wrapper__top {
  background: url(../images-ss/body-bcg.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    @include media("<=810px") {
      background-size: 180%;
      
    }
    @include media("<=430px") {
      background-size: 290%;
    }
}



.section__title {
  color: #1F1A1A;
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include media("<=810px") {
    font-size: 42px;
    text-align: center;
  }
  @include media("<=430px") {
    font-size: 32px;
  }
  
}

.section__subtitle {
  text-align: center;
  padding-top: 16px;
}



//BURGER BUTTON//
.burger__button {
  display: none;

  @include media("<=810px") {
    display: block;
    position: fixed;
    top: 28px;
    right: 35px;
    z-index: 200;
  }

  @include media("<=430px") {
    top: 24px;
    right: 20px;
  }

  @include media("<=810px") {
    &.active {
      position: fixed;
      top: 36px;
      right: 35px;
      z-index: 200;
    }
    @include media("<=430px") {
      &.active {
        top: 32px;
        right: 20px;
      }
    }
  }

  .line {
    height: 6px;
    width: 58px;
    margin-bottom: 8px;
    background-color: #ffffff;
    transition: all 200ms ease-in-out;
    opacity: 1;
    @include media("<=736px") {
      height: 4px;
      width: 48px;
    }
  }

  &.active {
    .line {
      background-color: #ffffff;
      &.one {
        transform: rotate(45deg) scale(1.2);
      }
      &.two {
        transform: rotate(-45deg) scale(1.2) translateY(-8px) translateX(8px);
        @include media("<=736px") {
          transform: rotate(-45deg) scale(1.2) translateY(-7px) translateX(7px);
        }
      }
      &.three {
        opacity: 0;
      }
    }
  }
}
